$(function(){

  $('#about-slides').carousel({
    interval:false
  });

  $('.about-carousel').slick({
    centerMode:true,
    infinite:false,
    initialSlide:2,
    slidesToShow:3,
    arrows:false,
    centerPadding:'20%'
  });

  var current = $('.about-carousel').slick('slickCurrentSlide');

  if(!isNaN(parseInt(current))){
    $('[data-slick-index='+current+']').addClass('about-center');
    $('[data-slick-index='+(current+1)+']').addClass('about-active');
    $('[data-slick-index='+(current-1)+']').addClass('about-active');
  }

  $('.about-carousel').on('swipe', function(event, slick, direction){
    $('#about-slides').carousel(slick.currentSlide);
    $('.slick-slide').removeClass('about-center');
    $('.slick-slide').removeClass('about-active');
    var current = $(this).slick('slickCurrentSlide');
    $('[data-slick-index='+current+']').addClass('about-center');
    $('[data-slick-index='+(current+1)+']').addClass('about-active');
    $('[data-slick-index='+(current-1)+']').addClass('about-active');
  });


  if(window.innerWidth<768){
    $('#about-slides').carousel(1);
  }

  $('[data-job-select]').click(function(){
    var selectIndex = $(this).data('job-select');
    $('.job-section').hide();
    $('.job-section:eq('+selectIndex+')').show();
  });

  $('[data-region-hover]').hover(function(){
    var index = $(this).data('region-hover');
    $('[data-region-hover]').removeClass('active');
    $(this).addClass('active');
    $('#mapImage').attr('src','/images/about/maps/'+index+'.png');
    $('.about-channel-item').hide();
    $('.about-channel-item:eq('+(index-1)+')').show();
  });


})
