$(function(){

  $('.blog-channel-title').click(function(e){
    if(window.innerWidth>1376){
      e.stopPropagation();
    }
  });

  $('[data-article-like]').click(function(){
    var articleId = $(this).data('article-like');
    if($.cookie('article_liked')){
      var articleLiked = $.cookie('article_liked').split(',');
      for(var n in articleLiked){
        if(articleLiked[n]==articleId){
          return;
        }
      }
    }else{
      var articleLiked = [];
    }
    articleLiked.push(articleId)
    $.get('/blog/'+articleId+'/like',function(res){
      $.cookie('article_liked',articleLiked,{expires:365});
      $('#likeCount').html(res.like);
    });
  });

});
