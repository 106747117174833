$(function(){

  $('.brand-carousel').slick({

    centerMode:true,
    infinite:false,
    initialSlide:4,
    slidesToShow:3,
    arrows:false,

  });

  var current = $('.brand-carousel').slick('slickCurrentSlide');

  if(!isNaN(parseInt(current))){
    $('[data-slick-index='+current+']').addClass('brand-center');
    $('[data-slick-index='+(current+1)+']').addClass('brand-active');
    $('[data-slick-index='+(current-1)+']').addClass('brand-active');
  }

  $('.brand-carousel').on('swipe', function(event, slick, direction){
    $('.slick-slide').removeClass('brand-center');
    $('.slick-slide').removeClass('brand-active');
    var current = $(this).slick('slickCurrentSlide');
    $('[data-slick-index='+current+']').addClass('brand-center');
    $('[data-slick-index='+(current+1)+']').addClass('brand-active');
    $('[data-slick-index='+(current-1)+']').addClass('brand-active');
  });


  //choice collapse

  $('.choice').on('show.bs.dropdown',function(){

    if(window.innerWidth < 768){

      $('.dropdown-menu').css('top',(256-$(window).scrollTop())+'px')
      $('.dropdown-menu').css('width',(window.innerWidth-20)+'px');

      if($(this).attr('data-choice')=='model'){
        $('.dropdown-menu .arrow').css('margin-left',((-120)+(window.innerWidth/2)) +'px'  );
      }else if($(this).attr('data-choice')=='series'){
        $('.dropdown-menu .arrow').css('margin-left',((-40)+(window.innerWidth/2)) +'px'  );
      }

      //unique style
      $('.choice hr').next('.unique').children('a').css('padding-left','0');
      $('.choice hr').prev('.unique').children('a').css('border-right','0');

    }else{

      $('.dropdown-menu').css('top','100%');
      $('.dropdown-menu').css('width','');
      $('.dropdown-menu .arrow').css('margin-left','-11px');
      $('.unique').children('a').css('padding-left','10px');
      $('.unique').children('a').css('border-right','1px solid #4A4A4A');
      //unique style

    }

    $('.unique:first').children('a').css('padding-left','0');
    $('.unique:last').children('a').css('border-right','0');



  });


  $('[data-toggle="collapse"]').click(function(e){
    if(window.innerWidth>768) return;
    e.preventDefault();
    $(this).parent().find('.collapse').slideToggle();
  })

  $('.scrollto').click(function() {

      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var $target = $(this.hash);
          $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
          if ($target.length) {
              var targetOffset = $target.offset().top-50;
              $('html,body').animate({
                          scrollTop: targetOffset
                      },
                      700);
              return false;
          }
      }
  });

  $('.production-images').carousel({interval:false});

  $('.production-images').mouseenter(function(){
    $(this).find('.production-image-select').css('display','')
  });

  $('.production-images').mousemove(function(e){

    var $originimage = $(this).find('.item.active img');

    offsetleft = e.pageX - $originimage.offset().left;
    offsettop = e.pageY - $originimage.offset().top;

    var x = offsetleft - 50;
    var y = offsettop - 50;
    if(x<0){
      x=0;
    }
    if(x>($originimage.width()-100)){
      x=($originimage.width()-100);
    }
    if(y<0){
      y=0;
    }
    if(y>($originimage.height()-100)){
      y=($originimage.height()-100);
    }

    $(this).find('.production-image-select').css('left',x);
    $(this).find('.production-image-select').css('top',y);

    //


    var $zoom = $('.production-image-zoom');
    var $zoomimage = $('.production-image-zoom img');

    var imageurl = $originimage.attr('src');
    var originwidth = $originimage.width();
    var originheight = $originimage.height();
    var zoomwidth = originwidth * 3;
    var zoomheight = originheight * 3;

    $zoom.css('display','block');
    $zoom.css('width',originwidth);
    $zoom.css('height',originheight);

    $zoomimage.css('width',zoomwidth);
    $zoomimage.css('height',zoomheight);

    $zoomimage.css('left',-x*3);
    $zoomimage.css('top',-y*3);
    $zoomimage.attr('src',imageurl);


  });

  $('.production-images').mouseleave(function(){
    $(this).find('.production-image-select').css('display','none');
    $('.production-image-zoom').css('display','none');
  });

  $('.production-indicators').mousemove(function(e){
    e.stopPropagation();
    $(this).parent().find('.production-image-select').css('display','none');
    $('.production-image-zoom').css('display','none');
  });

  $('[data-tag-choice]').click(function(){
    var index = $(this).data('tag-choice');
    $('.production-carousel').css('display','none');
    $('.production-carousel:eq('+index+')').css('display','block');
    $('.production-choice li').removeClass('active');
    $(this).parent().addClass('active');
    $('.sku-item').css('display','none');
    $('.sku-item:eq('+index+')').css('display','block');
  });

  $('[data-item-like]').click(function(){
    var $likeLink = $(this);
    var productionId = $(this).data('item-like');
    if($.cookie('production_liked')){
      var productionLiked = $.cookie('production_liked').split(',');
      for(var n in productionLiked){
        if(productionLiked[n]==productionId){
          return;
        }
      }
    }else{
      var productionLiked = [];
    }
    productionLiked.push(productionId);
    $.get('/production/'+productionId+'/like',function(res){
      $.cookie('production_liked',productionLiked,{expires:365});
      $likeLink.find('span').html(res.like);
    });
  });

  $('.production-format li').each(function(key,item){
    if($(item).outerHeight()>50){
      $('.production-format li').outerHeight($(item).outerHeight());
    }
  });

})
