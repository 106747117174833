$(function() {

  FastClick.attach(document.body);

  $("#slide").carousel();

  $('[data-weibo] img').popover({
    content:'<img src="/images/weibo-qrcode.png" style="width:150px;height:150px;" alt="" />',
    html:'true',
    placement:'top',
    trigger:'focus'
  });

  $('[data-weixin] img').popover({
    content:'<img src="/images/weixin-qrcode.jpg" style="width:150px;height:150px;" alt="" />',
    html:'true',
    placement:'top',
    trigger:'focus'
  });

  $('#querySearch').change(function(){
    if($(this).val()){
      $('.search-clear').show();
    }else{
      $('.search-clear').hide();
    }
  });

  $('.search-clear').click(function(){
    $('#querySearch').val('');
    $(this).hide();
  });

  $('[data-toggle="slidecomment"]').click(function(){
    $(this).toggleClass('active');
    var $target = $(this).parent().next('.commentto');
    $target.slideToggle();

  });

  $('[data-toggle="slideshare"]').click(function(){
    $(this).toggleClass('active');
    var $target = $(this).next('.shareto');
    var top = $(this).position().top;
    $target.css('top',top-5);
    $target.toggle();
  });

  var page = 1;

  $('[data-more]').click(function(){
    var type = $(this).data('more');
    page += 1;
    var url = location.origin+'/';
    switch ($(this).data('more')) {
      case 'comment':
        var href = location.href+'/comment/';
        break;
      case 'article':
        var href = location.href.replace('blog','article')+'/';
        break;
      default:
        var href = location.href+'/';
        break;
    }
    $.ajax({
      url:href+'?page='+page,
      type:'get',
      dataType:'json',
      success:function(res){
        switch (type) {
          case 'production':
            for(var n in res.data){
              var images = res.data[n].images;
              var cover = null;
              for(var i in images){
                if(images.cover){
                  cover = images.cover;
                }
              }
              if(!cover){
                cover = {};
                cover.src = 'images/noimage.svg';
              }
              $('.more-item:last').after(
              '<div class="col-sm-3 col-xs-6 items-bottom-border more-item">'+
                '<div class="item">'+
                  '<div class="item-image">'+
                    '<a href="'+href+(res.data[n].url?res.data[n].url:res.data[n].seo_title)+'">'+
                      '<img src="'+url+cover.src+'" alt="'+cover.alt+'">'+
                    '</a>'+
                    '<a href="'+href+(res.data[n].url?res.data[n].url:res.data[n].seo_title)+'">'+
                      '<div class="item-modal">'+
                        '<div class="item-modal-content">'+
                          '<div class="item-modal-brand">'+res.data[n].brand.name+'</div>'+
                          '<div class="item-modal-name">'+res.data[n].name+'</div>'+
                          '<div class="item-modal-model">'+res.data[n].machine.name+'</div>'+
                        '</div>'+
                      '</div>'+
                    '</a>'+
                  '</div>'+
                  '<a href="javascript:void(0);" class="item-like-link">'+
                    '<img src="'+url+'images/icons/heart.png" alt="" class="item-like">'+
                    '<span>'+res.data[n].like+'</span>'+
                  '</a>'+
                '</div>'+
              '</div>'
              );
            }
            break;
          case 'comment':
            for(var n in res.data){
              var item = $('.more-item:first').clone();
              if(res.data[n].pid){
                if(item.find('.comment-reply').length>0){
                  item.find('.comment-reply-to').text(res.data[n].parent.nick);
                }else{
                  item.find('.comment-content').before(
                  '<div class="text-muted comment-reply" style="margin-top:5px">'+
                    '<b>'+
                      '<span>'+
                        '<i class="ion-chatbubble-working"></i>'+
                        '<span>'+item.find('.ion-chatbubble-working').parent().text()+'</span>'+
                      '</span>：'+
                      '<span class="comment-reply-to">'+res.data[n].parent.nick+'</span>'+
                    '</b>'+
                  '</div>');
                }
              }else{
                item.find('.comment-reply').remove();
              }
              item.find('.comment-name').text(res.data[n].nick);
              item.find('.comment-time').text(res.data[n].created_at);
              item.find('.comment-content').text(res.data[n].content);
              item.find('form').attr('active','/comment/'+res.data[n].id+'/comment');
              $('.more-item:last').after(item);
            }
            break;
          case 'article':
            for(var n in res.data){
              if($('<div/>').html(res.data[n].content).text().length>200){
                res.data[n].content = $('<div/>').html(res.data[n].content).text().substring(0,200) + '...';
              }else{
                res.data[n].content = $('<div/>').html(res.data[n].content).text();
              }
              $('.more-item:last').after(
              '<div class="blog-border blog-container more-item">'+
                '<a href="'+href.replace('article','blog')+(res.data[n].url?res.data[n].url:res.data[n].seo_title)+'"><div class="blog-image img-cover" style="background-image:url('+url+res.data[n].cover_src+')"></div></a>'+
                '<div class="row unmargin">'+
                  '<div class="col-sm-10 col-sm-offset-1">'+
                    '<div class="blog-header">'+
                      '<div class="pull-left">'+
                        '<img src="'+url+res.data[n].category.icon_src+'" alt="'+res.data[n].category.icon_alt+'" width="16" height="22.5">'+
                        '<span class="text-muted">'+res.data[n].category.name+'</span>'+
                        '<img src="'+url+'images/icons/eye.png" alt="" width="30.4" height="17.3">'+
                        '<span class="text-muted">'+res.data[n].view+'</span>'+
                        '<img src="'+url+'images/icons/comment.png" alt="" width="20" height="20">'+
                        '<span class="text-muted">'+res.data[n].comments.length+'</span>'+
                      '</div>'+
                      '<div class="pull-right">'+
                        '<span class="text-muted">'+res.data[n].created_at+'</span>'+
                      '</div>'+
                    '</div>'+
                  '</div>'+
                  '<div class="col-xs-10 col-xs-offset-1">'+
                    '<a href="'+href.replace('article','blog')+(res.data[n].url?res.data[n].url:res.data[n].seo_title)+'">'+
                      '<div class="blog-title">'+
                      res.data[n].title+
                      '</div>'+
                      '<p>'+res.data[n].content+'</p>'+
                    '</a>'+
                  '</div>'+
                '</div>'+
              '</div>');

            }
            break;
        }
      }
    });
  });

});
