$(function(){

  $('.serices-list').slick({
    slidesToShow: 5,
    infinite: false,
    prevArrow:'#prev',
    nextArrow:'#next'
  });


  $('.serices-carousel').slick({

    centerMode:true,
    infinite:false,
    initialSlide:1,
    slidesToShow:3,
    arrows:false,

  });

  var current = $('.serices-carousel').slick('slickCurrentSlide');

  if(window.innerWidth < 768){
    $('.brand-box').hide();
    $('.brand-box:eq('+current+')').show();
  }

  if(!isNaN(parseInt(current))){
    $('[data-slick-index='+current+']').addClass('serices-center');
    $('[data-slick-index='+(current+1)+']').addClass('serices-active');
    $('[data-slick-index='+(current-1)+']').addClass('serices-active');
  }

  $('.serices-carousel').on('swipe', function(event, slick, direction){
    $('.slick-slide').removeClass('serices-center');
    $('.slick-slide').removeClass('serices-active');
    var current = $(this).slick('slickCurrentSlide');
    $('[data-slick-index='+current+']').addClass('serices-center');
    $('[data-slick-index='+(current+1)+']').addClass('serices-active');
    $('[data-slick-index='+(current-1)+']').addClass('serices-active');
    $('.brand-box').hide();
    $('.brand-box:eq('+current+')').show();
  });

  $('[data-brand-select]').click(function(){
    var index = $(this).data('brand-select');
    $('.brand-box').hide();
    $('.brand-box:eq('+index+')').show();
  });


})
