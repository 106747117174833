$(function(){

  if(window.innerWidth>768){
    $('.question-list').removeClass('collapse');
  }else{
    getPageData('production',1);
    getPageData('pay',1);
    getPageData('activity',1);
  }

  function getPageData(type,page){
    $.ajax({
      url:location.href+'/'+type+'/more?page='+page,
      type:'GET',
      dataType:'json',
      success:function(res){

        $('.'+type+'-question-list li').remove();

        for(var n in res.data){
          $('.'+type+'-question-list').prepend('<li><a href="'+location.href+'/'+res.data[n].id+'">'+res.data[n].title+'</a></li>');
        }

        $('.'+type+'-pagination').empty();

        if(res.current_page==1){
          $('.'+type+'-pagination').append('<li class="disabled"><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+1+'"><i class="ion-ios-arrow-left"></i></a></li>');
        }else{
          $('.'+type+'-pagination').append('<li><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+1+'"><i class="ion-ios-arrow-left"></i></a></li>');
        }

        if(res.last_page>4){
          if(res.current_page>3){
            var startpage = res.current_page - 2;
            var endpage = startpage + 3;
            if(endpage>res.last_page){
              endpage = res.last_page;
              startpage = res.last_page-3;
            }
          }else{
            var endpage = 4;
            var startpage = 1;
          }
        }else{
          var endpage = res.last_page;
          var startpage = 1;
        }

        for(var n=startpage;n<=endpage;n++){
          if(res.current_page==n){
            $('.'+type+'-pagination').append('<li class="active"><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+n+'">'+n+'</a></li>');
          }else{
            $('.'+type+'-pagination').append('<li><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+n+'">'+n+'</a></li>');
          }
        }

        if(res.current_page==res.last_page){
          $('.'+type+'-pagination').append('<li class="disabled"><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+res.last_page+'"><i class="ion-ios-arrow-right"></i></a></li>');
        }else{
          $('.'+type+'-pagination').append('<li><a href="javascript:void(0);" data-type="'+type+'" data-paginate="'+res.last_page+'"><i class="ion-ios-arrow-right"></i></a></li>');
        }



        $('[data-paginate]').click(function(){
          var page = $(this).data('paginate');
          var type = $(this).data('type');
          getPageData(type,page);
        });
      }
    });
  }



  $('.question-head').click(function(){
    if(window.innerWidth<768){
      if(!$('.question-list').hasClass('collapse')){
          $('.question-list').addClass('collapse');
      }
    }
  });

  $('.question-head .more').click(function(e){
    e.stopPropagation();
  });



});
